import { notifyMessage, notifyProblem, notifyError } from '@/services/notify'
import { orderBy } from 'lodash'

export const methods = {
  confirmUnitNoteDelete(note, id) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Property Note',
      message: `Are you sure you want to <b>delete</b> this Property Note, '${note}'?`,
      confirmText: 'Delete Property Note',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteUnitNote(id)
    })
  },
  confirmOwnerNoteDelete(note, id) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Owner Note',
      message: `Are you sure you want to <b>delete</b> this Owner Note, '${note}'?`,
      confirmText: 'Delete Owner Note',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteOwnerNote(id)
    })
  },

  reload() {
    this.getRefreshedUnitNotes()
    this.getRefreshedOwnerNotes()
  },

  openUnitNoteModal(action) {
    this.selectedID = null
    this.selectedNote = ''
    this.action = action
    this.unitNotesModal = true
  },
  openOwnerNoteModal(action) {
    this.selectedID = null
    this.selectedNote = ''
    this.action = action
    this.ownerNotesModal = true
  },

  loadAddNote() {
    this.selectedID = null
    this.selectedNote = ''
    this.formShow = !this.formShow
    this.action = this.action == '' ? 'add' : ''
  },
  loadUpdateNote(note, id) {
    this.selectedID = id
    this.selectedNote = note
    this.formShow = true
    this.action = 'update'
  },

  initButtons() {
    this.selectedID = null
    this.selectedNote = ''
    this.formShow = false
    this.action = ''
  },

  // Unit Notes ~ API calls
  async getRefreshedUnitNotes() {
    const { results } = await this.kms.get(`/Roster/UnitNote/List`, {
      params: {
        unitID: this.$route.params.id
      }
    })

    this.unitNoteList = orderBy(results, 'createdDate', 'desc')

    if (this.isDebug == true) console.debug('results=' + JSON.stringify(this.unitNoteList))

    this.initButtons()
  },
  async addUnitNote(note) {
    if (this.isDebug == true) console.debug('in addUnitNote...')

    if (!note) {
      notifyError('Please enter a note. Note is a required field.')
      return
    }

    if (this.unitID && this.unitID != undefined && this.unitID > 0 && note && note != undefined) {
      try {
        this.loading = true
        const payload = {
          unitID: this.unitID,
          note: note
        }
        const path = `/Roster/UnitNote`
        const results = await this.kms.post(path, payload)

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (results.unitNoteID > 0) {
          this.getRefreshedUnitNotes()
          notifyMessage(`Successfully added property note.`)
          this.unitNotesModal = false
        } else {
          notifyProblem('There was a problem adding the new property note.')
        }
      } catch (e) {
        notifyProblem(e)
      }
    }

    this.loading = false
  },
  async deleteUnitNote(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this property note.')
      return
    }

    try {
      this.loading = true
      const data = { unitNoteID: id, forceDelete: false }
      const path = `/Roster/UnitNote/${id}?forceDelete=false`
      const results = await this.kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.formComplete = true

        this.getRefreshedUnitNotes()

        notifyMessage(`The selected property note was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this property note.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },
  async updateUnitNote(note, id) {
    if (this.isDebug == true) console.debug(JSON.stringify(note))

    if (!note || id <= 0 || !this.unitID) {
      notifyProblem('Unable to update this property note.')
      return
    }

    try {
      this.loading = true
      const data = {
        unitNoteID: id,
        unitID: this.unitID,
        note: note
      }
      const path = `/Roster/UnitNote`
      const results = await this.kms.put(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.unitNoteID >= 0) {
        this.formComplete = true

        this.getRefreshedUnitNotes()

        notifyMessage(`The selected property note was successfully updated.`)

        this.unitNotesModal = false
      } else {
        notifyProblem('There was a problem updating this property note.')
      }
    } catch (e) {
      notifyProblem(e)
    }
    this.loading = false
  },

  // Owner Notes ~ API calls
  async getRefreshedOwnerNotes() {
    const { results } = await this.kms.get(`/Roster/OwnerNote/List`, {
      params: {
        ownerID: this.ownerID
      }
    })

    this.ownerNoteList = orderBy(results, 'createdDate', 'desc')

    if (this.isDebug == true) console.debug('results=' + JSON.stringify(this.ownerNoteList))

    this.initButtons()
  },
  async deleteOwnerNote(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this owner note.')
      return
    }

    try {
      this.loading = true
      const data = { ownerNoteID: id, forceDelete: false }
      const path = `/Roster/OwnerNote/${id}?forceDelete=false`
      const results = await this.kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.formComplete = true

        this.getRefreshedOwnerNotes()

        notifyMessage(`The selected owner note was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this owner note.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },
  async updateOwnerNote(note, id) {
    if (this.isDebug == true) console.debug(JSON.stringify(note))

    if (!note || id <= 0 || !this.ownerID) {
      notifyProblem('Unable to update this owner note.')
      return
    }

    try {
      this.loading = true
      const data = {
        ownerNoteID: id,
        ownerID: this.ownerID,
        note: note
      }
      const path = `/Roster/OwnerNote`
      const results = await this.kms.put(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.ownerNoteID >= 0) {
        this.formComplete = true

        this.getRefreshedOwnerNotes()

        notifyMessage(`The selected owner note was successfully updated.`)
        this.ownerNotesModal = false
      } else {
        notifyProblem('There was a problem updating this owner note.')
      }
    } catch (e) {
      notifyProblem(e)
    }
    this.loading = false
  },

  async onFormSubmit() {
    await this.addOwnerNote()
  },

  async addOwnerNote() {
    if (this.isDebug == true) console.debug('in addOwnerNote...' + this.ownerID)

    if (!this.selectedNote) {
      notifyError('Please enter a note. Note is a required field.')
    }

    if (
      this.ownerID &&
      this.ownerID != undefined &&
      this.ownerID > 0 &&
      this.selectedNote &&
      this.selectedNote != undefined
    ) {
      try {
        this.loading = true
        const payload = {
          ownerID: this.ownerID,
          note: this.selectedNote
        }
        const path = `/Roster/OwnerNote`
        const results = await this.kms.post(path, payload)

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (results.ownerNoteID > 0) {
          this.getRefreshedOwnerNotes()
          notifyMessage(`Successfully added owner note.`)
          this.ownerNotesModal = false
        } else {
          notifyProblem('There was a problem adding the new owner note.')
        }
      } catch (e) {
        notifyProblem(e)
      }
    }

    this.loading = false
  }
}
